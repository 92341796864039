<template>
  <div class="vlt-placeholder">
    <div class="vlt-div" :style="{paddingRight:`${gutter}px`,width:`${fraction}%`}" v-for="(num, key) in Math.ceil(numOfTimes)" :key="key">
    
    <div :class="['image-block',title !== 'Movies' ? 'ft-slide-landscape' : 'ft-slide-potrait']"></div>
   
      <div class="vlt-desc1"></div>
      <div class="vlt-desc2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Placeholder",
  data() {
    return {
      imageLandscape: require("@/assets/icons/placeholder-landscape.png"),
      imagePotrait: require("@/assets/icons/placeholder-potrait.png"),
      numOfTimes: "",
      fraction:100,
    };
  },
  props: ["title", "noOfDisplay","gutter"],
  created() {
    console.log(this.noOfDisplay,this.gutter);
    if (this.noOfDisplay) {
      this.numOfTimes = this.noOfDisplay;
    } else {
      this.numOfTimes = 1;
    }
    this.fraction=(100/this.numOfTimes).toFixed(2);
  }
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_mediaQueries.scss";

.vlt-placeholder {
  display: flex;
  .vlt-div {
    flex-shrink: 0;
    .image-block{
      position:relative;
      width:100%;
      height:0;
    }

    .ft-slide-landscape {
      padding: 28.125% 0;
      background: transparent
        linear-gradient(50deg, $clr-bg-gray-light-9 0%, $clr-bg-gray-dark 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px $clr-color-dark-1;
      border-radius: 5px;
      opacity: 1;
    }

    .ft-slide-potrait {
      padding:75% 0;
      background: transparent
        linear-gradient(50deg, $clr-bg-gray-light-9 0%, $clr-bg-gray-dark 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px $clr-color-dark-1;
      border-radius: 5px;
      opacity: 1;
    }
    .vlt-desc1 {
      width: 50%;
      height: 1rem;
      background: $clr-bg-gray-dark-2 0% 0% no-repeat padding-box;
      opacity: 1;
      margin-top: 1rem;
    }

    .vlt-desc2 {
      width: 20%;
      height: 1rem;
      background: $clr-bg-gray-dark-2 0% 0% no-repeat padding-box;
      opacity: 1;
      margin-top: 1rem;
      margin-bottom:2rem;
    }
  }



  
}
  

</style>